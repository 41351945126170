import {storage} from './firebase';

function uploadFile(file,fileName,setSelectedFileFirebasePath,setDownloadUrl){
    const picsRef = storage.ref('/event2020_pics/'+fileName);
    picsRef.put(file).then(function(snapshot) {
        //console.log('Uploaded a blob or file!: '+snapshot);
        if(setSelectedFileFirebasePath != null)
        {
            setSelectedFileFirebasePath(snapshot.ref.location.path_);
            getDownloadURL(snapshot.ref.location.path_,setDownloadUrl);
        }
    });
}

function getDownloadURL(path,setDownloadUrl){
    const picsRef = storage.ref(path);
    picsRef.getDownloadURL().then(function(url) {
        if(setDownloadUrl != null)
        {
            setDownloadUrl(url);
        }
        console.log('Got download URL: '+url);
    });
}

export default {uploadFile,getDownloadURL}