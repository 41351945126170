import {db} from './firebase';

function getGrades(){
    return db.collection("standard")
        .orderBy('seq', 'asc').get();
}

function getAndSetGrades(setGrades){
    getGrades().then((querySnapshot) => {
        /*querySnapshot.forEach((doc) => {
            console.log(`${doc.id} => ${doc.data()}`);
        });*/
        var docData = querySnapshot.docs.map(doc => doc.data())
        setGrades(docData);
    });
}

export default {getGrades,getAndSetGrades}