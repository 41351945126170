import {db} from './firebase';

const collectionName = 'registration';
const idPrefix = 'REG';

function registerForm(registration, successCallback, errorCallback) {
    //Transaction
    db.runTransaction(function (transaction) {
        const queryRef = db.collection(collectionName)
            .orderBy('regId', 'desc')
            .limit(1).get();
        queryRef.then((querySnapshot) => {
            var lastRegId = 'REG10000';
            var newRegId = 'REG10001';
            if (querySnapshot.docs.length > 0) {
                var data = querySnapshot.docs.map(data => data.data())[0];
                lastRegId = data.regId;
                var seqNo = parseInt(lastRegId.substr(3));//10000
                newRegId = 'REG' + (seqNo + 1);
            }
            registration.regId = newRegId;
            registration.createdDtMS = new Date().getTime();
            db.collection(collectionName).add(registration)
                .then(function (docRef) {
                    //console.log("Document written with ID: ", docRef.id);
                    docRef.regNo = newRegId;
                    successCallback(docRef);
                })
                .catch(function (error) {
                    //console.error("Error adding document: ", error);
                    errorCallback(error);
                });
        });
    })
        .then(() => console.log("Transaction successfully committed!"))
        .catch((error) => console.log("Transaction failed: ", error));
}

function getRegistrations() {
    return db.collection(collectionName).get();
}

function getAndSetRegistrations(setRegistrations) {
    getRegistrations().then((querySnapshot) => {
        /*querySnapshot.forEach((doc) => {
            console.log(`${doc.id} => ${doc.data()}`);
        });*/
        var data = querySnapshot.docs;
        var docData = data.map(doc => {
            var obj = doc.data();
            obj.id = doc.id;
            return obj;
        })
        setRegistrations([]);
        setRegistrations(docData);
    });
}

function deleteRegistration(id, successCallback, errorCallback) {
    db.collection(collectionName).doc(id).delete().then(function () {
        console.log("Document successfully deleted!");
        if (successCallback) {
            successCallback();
        }
    }).catch(function (error) {
        console.error("Error removing document: ", error);
        if (errorCallback) {
            errorCallback(error);
        }
    });
}


export default {registerForm, getAndSetRegistrations, getRegistrations, deleteRegistration}