import React, {useEffect, useState} from "react";
import firebaseAuth from "../store/firebaseAuth";
import registrationService from "../store/registrationService";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import storageService from "../store/storageService";
import RefreshIcon from '@material-ui/icons/Refresh';
import { useHistory } from 'react-router-dom';
import {CloudDownload} from "@material-ui/icons";

const columns = [
    { id: 'regId', label: 'Reg ID', minWidth: 170 },
    { id: 'name', label: 'Name', minWidth: 170 },
    { id: 'email', label: 'Email', minWidth: 100 },
    {
        id: 'contact',
        label: 'Contact',
        minWidth: 170,
    },
    {
        id: 'place',
        label: 'Place',
        minWidth: 170,
    },
    {
        id: 'std',
        label: 'Grade',
        minWidth: 170,
    },
    {
        id: 'school',
        label: 'School',
        minWidth: 170,
    },
    {
        id: 'eventCategory',
        label: 'Events',
        minWidth: 170,
    },
];

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 440,
    },
});

function createData(name, code, population, size) {
    const density = population / size;
    return { name, code, population, size, density };
}

const rows = [
    createData('India', 'IN', 1324171354, 3287263),
    createData('China', 'CN', 1403500365, 9596961),
    createData('Italy', 'IT', 60483973, 301340),
    createData('United States', 'US', 327167434, 9833520),
    createData('Canada', 'CA', 37602103, 9984670),
    createData('Australia', 'AU', 25475400, 7692024),
    createData('Germany', 'DE', 83019200, 357578),
    createData('Ireland', 'IE', 4857000, 70273),
    createData('Mexico', 'MX', 126577691, 1972550),
    createData('Japan', 'JP', 126317000, 377973),
    createData('France', 'FR', 67022000, 640679),
    createData('United Kingdom', 'GB', 67545757, 242495),
    createData('Russia', 'RU', 146793744, 17098246),
    createData('Nigeria', 'NG', 200962417, 923768),
    createData('Brazil', 'BR', 210147125, 8515767),
];

const RegistrationsComponent = () => {
    const [registrations, setRegistrations] = useState([]);
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
    const [selectedRegistration, setSelectedRegistration] = React.useState({});
    const history = useHistory();
    const handleClick = () => history.push('/goodbye');
    //const excelDownloadLink = 'https://us-central1-event2020-d5ae5.cloudfunctions.net/app/export/excel'; //TODO enable security
    const excelDownloadLink = 'https://ingenium-backend.herokuapp.com/export/excel';

    const handleDeleteDialog = (row) => {
        console.log('handleDeleteDialog');
        setSelectedRegistration(row);
        setOpenDeleteDialog(true);
    };

    const handleDeleteDialogClose = (event,shouldDelete) => {
        console.log('handleDeleteDialogClose');
        setOpenDeleteDialog(false);
        if(shouldDelete){
            registrationService.deleteRegistration(selectedRegistration.id,deletedSuccesfully,deletionFailed);
        }
    };
    const deletedSuccesfully = () => {
        registrationService.getAndSetRegistrations(setRegistrations);
    }
    const deletionFailed = (error) => {

    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    useEffect(() => {
        console.log(firebaseAuth.GetSignedInUser());

        loadRegistrations();
        return;
        if(firebaseAuth.GetSignedInUser() == null) {
            firebaseAuth.SignInWithPopup(loadRegistrations);
        }
    }, []);
    const loadRegistrations = () => {
        registrationService.getAndSetRegistrations(setRegistrations)
    }
    const downloadExcel = () => {
        window.open(
            excelDownloadLink, "_blank");
    }
    const picDownloadURL = () => {

    }
    return(
        <div>
            <Paper className={classes.root}>
                <IconButton aria-label="delete" className={classes.margin} onClick={loadRegistrations} style={{float:"right"}}>
                    <RefreshIcon fontSize="default" />
                </IconButton>
                <IconButton aria-label="delete" className={classes.margin} onClick={downloadExcel} style={{float:"right"}}>
                    <CloudDownload fontSize="default" />
                </IconButton>
                <TableContainer className={classes.container}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth }}
                                    >
                                        <b>{column.label}</b>
                                    </TableCell>
                                ))}
                                <TableCell key={'photo'} align={''}>
                                    <b>Photo</b>
                                </TableCell>
                                <TableCell key={'deleteButton'} align={''}>
                                    <b>Action</b>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {registrations.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.email}>
                                        {columns.map((column) => {
                                            const value = row[column.id];
                                            return (
                                                <TableCell key={column.id} align={column.align}>
                                                    {column.format && typeof value === 'number' ? column.format(value) : value}
                                                </TableCell>
                                            );
                                        })}
                                        <TableCell key={'photo'} align={''}>
                                           <img style={{width:'100px'}} src={row.picDownloadPath}/>
                                        </TableCell>
                                        <TableCell key={'deleteButton'} align={''}>
                                            <IconButton aria-label="delete" className={classes.margin} onClick={(event) => handleDeleteDialog(row)}>
                                                <DeleteIcon color="secondary" fontSize="small"/>
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={registrations.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>
            <Dialog
                open={openDeleteDialog}
                onClose={(event) => handleDeleteDialogClose(event,false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" color="secondary">{"Delete Registration?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure!
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={(event) => handleDeleteDialogClose(event,false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={(event) => handleDeleteDialogClose(event,true)} color="primary" autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default RegistrationsComponent;