import React, {useEffect} from "react";
import {sendSms} from "../service/twiliosms";
import RegistrationsComponent from "./Registrations";

const SmsComponent = () => {
    useEffect(()=>{
        sendSms('+919944824995')
    });
    return (
        <div>SMS</div>
    )
}

export default SmsComponent;