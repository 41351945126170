const accountSid = 'AC241feacfff440d62697eb06182f6d305';
const authToken = '2cd406273612d91778636e0a27d53c81';
const client = require('twilio')(accountSid, authToken);

const sendSms = function(to){
    client.messages
        .create({
            body: 'This is the ship that made the Kessel Run in fourteen parsecs?',
            from: '+15017122661',
            to: to
        })
        .then((message) => {
            console.log(message.sid)
        })
        .catch((error) => {
            console.log(error)
        });
}

export {sendSms}