import {db} from './firebase';

function getLocations(){
    return db.collection("location")
        .orderBy('name', 'asc').get();
}

function getAndSetLocations(setLocations){
    getLocations().then((querySnapshot) => {
        /*querySnapshot.forEach((doc) => {
            console.log(`${doc.id} => ${doc.data()}`);
        });*/
        var data = querySnapshot.docs;
        var docData = data.map(doc => doc.data())
        setLocations(docData);
    });
}

export default {getLocations,getAndSetLocations}