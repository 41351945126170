import { db } from './firebase';

function getEventCategory(){
    return db.collection("eventCategory")
        .orderBy('name', 'asc').get();
}

function getAndSetEventCategory(grade,setEventCategories){
    getEventCategory().then((querySnapshot) => {
        /*querySnapshot.forEach((doc) => {
            console.log(`${doc.id} => ${doc.data()}`);
        });*/
        var data = querySnapshot.docs;
        var docData = data.map(doc => doc.data())
        var finalEvents = [];
        if(grade != null)
        {
            finalEvents = docData.filter((event) => {
                if(event.std != null && event.std != '')
                {
                    if(event.std.split(',').indexOf(grade) != -1)
                    {
                        return event;
                    }
                }
            })
        }
        setEventCategories(finalEvents);
    });
}

export default {getEventCategory,getAndSetEventCategory}