import React from "react";
import {Link} from "react-router-dom";

export default function AdminComponent() {
    return (
        <div>
            <div>Admin</div>
            <div>
                <Link to="/registrations">Registrations</Link>
            </div>
        </div>
    )
}