import React, {useEffect, useState} from 'react';
import {Theme, createStyles, makeStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import locationService from "../store/locationService";
import gradeService from "../store/gradeService";
import schoolService from "../store/schoolService";
import eventService from "../store/eventService";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Autocomplete from '@material-ui/lab/Autocomplete';
import Input from "@material-ui/core/Input";
import useTheme from "@material-ui/core/styles/useTheme";
import Chip from "@material-ui/core/Chip";
import Button from "@material-ui/core/Button";
import registrationService from "../store/registrationService";
import Alert from "@material-ui/lab/Alert";
import storageService from "../store/storageService";
import Container from "@material-ui/core/Container";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '45ch',
        },
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: '45ch',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    autoComplete: {
       /* marginLeft: '-105px',*/
    },
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

export default function RegistrationForm() {
    const theme = useTheme();
    const [name, setName] = useState('');
    const [contact, setContact] = useState('');
    const [validContact, setValidContact] = useState(false);
    const [photo, setPhoto] = useState('');
    const [email, setEmail] = useState('');
    const [validEmail, setValidEmail] = useState(false);
    const [locations, setLocations] = useState([]);
    const [place, setPlace] = useState('');
    const [grades, setGrades] = useState([]);
    const [grade, setGrade] = useState('');
    const [schools, setSchools] = useState([]);
    const [school, setSchool] = useState('');
    const [schoolAuto, setSchoolAuto] = useState({});
    const [eventCategories, setEventCategories] = useState([]);
    const [eventCategory, setEventCategory] = useState([]);
    const [validForm, setValidForm] = useState(true);
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedFileFirebasePath, setSelectedFileFirebasePath] = useState('');
    const [downloadUrl, setDownloadUrl] = useState('');
    const [fileuploadError, setFileuploadError] = useState(false);
    const [successMsg, setSuccessMsg] = useState('');

    useEffect(() => {
        locationService.getAndSetLocations(setLocations);
        gradeService.getAndSetGrades(setGrades);
    }, []);

    const handlePlaceChange = (event) => {
        setPlace(event.target.value);
        setSchool('');
        schoolService.getAndSetSchoolsInLocation(event.target.value, setSchools);
    };
    const handleNameChange = (event) => {
        setName(event.target.value);
    };
    const handleEmailChange = (event) => {
        setEmail(event.target.value);
        //console.log(event.target.value);
        const valid = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(event.target.value);
        //console.log(valid);
        setValidEmail(valid);
    };
    const handleContactChange = (event) => {
        setContact(event.target.value);
        const pattern = new RegExp("^[0-9]{10}$");
        const valid = pattern.test(event.target.value);
        setValidContact(valid);
    };
    const handleGradeChange = (event) => {
        setGrade(event.target.value);
        setEventCategory([]);
        setEventCategories([]);
        eventService.getAndSetEventCategory(event.target.value,setEventCategories);
    };
    const handleSchoolChange = (newName) => {
        setSchool(newName);
    };
    const handleEventCategoryChange = (event) => {
        setEventCategory(event.target.value);
    };
    const onFileChange = (event) => {
        const imageFile = event.target.files[0];
        //validation
        if(imageFile== null || imageFile.type.toString().indexOf('image/') == -1)
        {
            setFileuploadError(true);
            return;
        }
        setSelectedFile(event.target.files[0])
        const imageName = new Date().getTime()+'_'+imageFile.name;
        storageService.uploadFile(event.target.files[0],imageName,setSelectedFileFirebasePath,setDownloadUrl);
    };
    const submitForm = (event) => {
        if (!validateForm()) {
            return;
        }
        console.log("submitForm");
        const registrationObj = {
            name: name,
            contact: contact,
            email: email,
            eventCategory: eventCategory,
            picPath: selectedFileFirebasePath,
            picDownloadPath: downloadUrl,
            place: place,
            school: school,
            std: grade,
            emailStatus: 'Q'
        }
        console.log(registrationObj);
        registrationService.registerForm(registrationObj, successRegCallback, errorRegCallback);
    }

    const validateForm = () => {
        console.log("validateForm");
        var valid = true;
        if (name.length < 3) {
            valid = false;
        }
        if (grade === '') {
            valid = false;
        }
        if (place === '') {
            valid = false;
        }
        if (school === '') {
            valid = false;
        }
        if (eventCategory.length <= 0) {
            valid = false;
        }
        if (!validContact) {
            valid = false;
        }
        if (!validEmail) {
            valid = false;
        }
        setValidForm(valid);
        return valid;
    }

    const successRegCallback = (docRef) => {
        console.log("Document written with ID: ", docRef.id);
        setSuccessMsg('Registration completed successfully, Your RegNo is '+docRef.regNo+' .Please check email for more details.');
        clearForm();
    }
    const errorRegCallback = (error) => {
        console.error("Error adding document: ", error);
    }
    const clearForm = () => {
        setName('');
        setGrade('');
        setPlace('');
        setSchool('');
        setSchoolAuto({});
        setEventCategory([]);
        setContact('');
        setEmail('');
        setValidContact(false);
        setValidEmail(false);
        setValidForm(true);
        setFileuploadError(false);
    }


    const classes = useStyles();

    return (
        <Container maxWidth="m">
        <div class="header_row">Jatayu</div>
        <div>
       <h1 class="title">JATAYU CHARITABLE TRUST</h1>
       <h2 class="title">Ingenium Registartion</h2>
        </div>
        <div>
        <hr></hr>
<h5>General Rules :</h5>
<p><span class="imp">*</span>Each participant will get a <b>Reg ID</b> in the end which will be used in the competition & for the judging process.</p>
<p><span class="imp">*</span>For any queries please contact our <b>Coordinators</b> or reach us through mail.</p>
<p><span class="imp">*</span>If your school name is not in the dropdown list just choose others.</p>
<p><span class="imp">*</span>If possible upload your picture<b>(Optional)</b></p>
<p><span class="imp">*</span>For any clarification reach us - 8122301417 & 8056203471.</p>
      <hr></hr>
        </div>
        <div class="row">
        <div class="col-sm-10">
            <form className={classes.root} noValidate autoComplete="off">
                <div>
                    <TextField required id="standard-required" label="Name" value={name} onChange={handleNameChange}
                               error={name === ""}/>
                </div>
                <div>
                    <FormControl className={classes.formControl}>
                        <InputLabel required id="grade-select-label" error={grade === ""}>Standard / Grade</InputLabel>
                        <Select
                            labelId="grade-select-label"
                            id="grade-select"
                            value={grade}
                            onChange={handleGradeChange}
                        >
                            {grades.map(grade => (
                                <MenuItem value={grade.name}>{grade.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                <div>
                    <FormControl className={classes.formControl}>
                        <InputLabel required id="place-select-label" error={place === ""}>Place</InputLabel>
                        <Select
                            labelId="place-select-label"
                            id="place-select"
                            value={place}
                            onChange={handlePlaceChange}
                        >
                            {locations.map(location => (
                                <MenuItem value={location.name}>{location.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                <div>
                    <FormControl className={classes.autoComplete}>
                        <Autocomplete
                            id="combo-box-schools"
                            options={schools}
                            value={schoolAuto}
                            onChange={(event, newValue) => {
                                if(newValue != null)
                                {
                                    handleSchoolChange(newValue.name);
                                    setSchoolAuto(newValue);
                                }
                                else
                                {
                                    handleSchoolChange('');
                                    setSchoolAuto({});
                                }
                            }}
                            getOptionLabel={(option) => option.name}
                            style={{width: 300}}
                            renderInput={(params) => <TextField {...params} label="School" error={school === ''}/>}
                        />
                    </FormControl>
                </div>
                <div>
                    <FormControl className={classes.formControl}>
                        <InputLabel id="eventCategory-mutiple-name-label"
                                    error={eventCategory.length === 0}>Events</InputLabel>
                        <Select
                            labelId="eventCategory-mutiple-name-label"
                            id="eventCategory-mutiple-name"
                            multiple
                            value={eventCategory}
                            onChange={handleEventCategoryChange}
                            input={<Input id="select-multiple-chip"/>}
                            renderValue={(selected) => (
                                <div className={classes.chips} >
                                    {selected.map((value) => (
                                        <Chip key={value} label={value} className={classes.chip} color="primary"/>
                                    ))}
                                </div>
                            )}
                            MenuProps={MenuProps}
                        >
                            {eventCategories.map((event) => (
                                <MenuItem key={event.name} value={event.name}>
                                    <Checkbox checked={eventCategory.indexOf(event.name) > -1} />
                                    <ListItemText primary={event.name} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                <div>
                    <TextField required id="standard-required" label="Contact No" type={'number'} value={contact}
                               onChange={handleContactChange} error={validContact !== true}/>
                </div>
                <div>
                    <TextField required id="standard-required" label="Email" value={email} type={'email'}
                               onChange={handleEmailChange} error={validEmail !== true}/>
                </div>
                <div style={{textAlign:"left", margin:'10px'}}>
                    {fileuploadError ?
                        <div>
                            <Alert severity="error">Error uploading file try again!</Alert>
                        </div> : null
                    }
                    <InputLabel>Upload Your Picture(Optional)</InputLabel>
                    <Button
                        variant="contained"
                        component="label"
                        size={'small'}
                    >
                        Upload File
                        <input
                            type="file"
                            style={{display: "none"}}
                            onChange={onFileChange}
                        />
                    </Button>
                </div>
                {!validForm ?
                    <div>
                        <FormControl>
                            <Alert severity="error">Please enter valid details!</Alert>
                        </FormControl>
                    </div> : null
                }
                {successMsg != '' ?
                    <div>
                        <FormControl>
                            <Alert severity="success">{successMsg}</Alert>
                        </FormControl>
                    </div> : null
                }
                <div style={{margin:'10px'}}>
                    <Button variant="contained" color="primary" onClick={submitForm} size={"large"}>
                        Register
                    </Button>
                    <Button style={{marginLeft:10}} variant="contained" color="secondary" onClick={clearForm} size={"medium"}>
                        Clear
                    </Button>
                </div>
            </form>
            </div>

            </div>

        </Container>
    );
}
