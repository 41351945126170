import {db} from './firebase';

function getSchoolsInLocation(location){
    var schoolRef = db.collection("school");
    var query = schoolRef.orderBy('name', 'asc').where("location", "==", location);
    return query.get();
}

function getAndSetSchoolsInLocation(location,setSchools){
    getSchoolsInLocation(location).then((querySnapshot) => {
        /*querySnapshot.forEach((doc) => {
            console.log(`${doc.id} => ${doc.data()}`);
        });*/
        var data = querySnapshot.docs;
        var docData = data.map(doc => doc.data())
        setSchools(docData);
    });
}

export default {getSchoolsInLocation,getAndSetSchoolsInLocation}