import * as firebase from "firebase";

var provider = new firebase.auth.GoogleAuthProvider();
//provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
firebase.auth().useDeviceLanguage();
var signedInUser = null;

function SignInWithPopup(setSignedInUser){
    firebase.auth().signInWithPopup(provider).then(function(result) {
        // This gives you a Google Access Token. You can use it to access the Google API.
        var token = result.credential.accessToken;
        // The signed-in user info.
        var user = result.user;
        // ...
        console.log(token);
        console.log(user);
        signedInUser = user;
        if(setSignedInUser != null)
        {
            setSignedInUser(signedInUser);
        }
    }).catch(function(error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // The email of the user's account used.
        var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential;
        // ...
        console.log(error);
    });
}

function GetSignedInUser(){
    return signedInUser;
}
export default {SignInWithPopup, GetSignedInUser}