import React, {useEffect} from 'react';
import './App.css';
import RegistrationForm from './components/RegistrationForm';
import AdminComponent from "./components/Admin";
import HomeComponent from "./components/Home";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useHistory
} from "react-router-dom";
import RegistrationsComponent from "./components/Registrations";
import makeStyles from "@material-ui/core/styles/makeStyles";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import useTheme from "@material-ui/core/styles/useTheme";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import firebaseAuth from "./store/firebaseAuth";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ListAltIcon from '@material-ui/icons/ListAlt';
import AddIcon from '@material-ui/icons/Add';
import { createMemoryHistory } from 'history'
import SmsComponent from "./components/sms";

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
}));

const App = () => {
    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const [loggedIn, setLoggedIn] = React.useState(false);
    const [user, setUser] = React.useState(null);
    const [isAdmin, setIsAdmin] = React.useState(false)
    const history = useHistory()

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    useEffect(() => {
    })

    const loginUser = (event) => {
        firebaseAuth.SignInWithPopup(setSignedInUser);
    }

    const setSignedInUser = (user) => {
        setUser(user);
        setLoggedIn(true);
    }


    function gotoScreen(path) {
        if(history != null)
        {
            history.push(path);
        }
    }

    return (
        <div className={classes.root}>
            {/*<Toolbar>
                <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={handleDrawerOpen}>
                    <MenuIcon />
                </IconButton>
                <Typography variant="h6" className={classes.title}>

                </Typography>
                {
                    !loggedIn ?
                        <Button color="inherit" onClick={loginUser}>Login</Button>
                        :
                        null
                }
            </Toolbar>*/}
            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="left"
                open={open}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <div className={classes.drawerHeader}>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </div>
                <Divider />
                <List>
                    <ListItem button key={'New Registration'} onClick={() => gotoScreen('/registration')}>
                        <ListItemIcon><AddIcon /></ListItemIcon>
                        <ListItemText primary={'New Registration'} />
                    </ListItem>
                    {
                        isAdmin ?
                            <ListItem button key={'Registrations'}  onClick={() => gotoScreen('/registrations')}>
                                <ListItemIcon><ListAltIcon /></ListItemIcon>
                                <ListItemText primary={'Registration List'} />
                            </ListItem>
                            : null
                    }
                    {
                        loggedIn ?
                            <ListItem button key={'Logout'}>
                                <ListItemIcon><ExitToAppIcon /></ListItemIcon>
                                <ListItemText primary={'Logout'} />
                            </ListItem>
                            :
                            null
                    }

                </List>
            </Drawer>
            <Router>
                <Switch>
                    <Route path="/registration">
                        <RegistrationForm></RegistrationForm>
                    </Route>
                    <Route path="/admin">
                        <AdminComponent/>
                    </Route>
                    <Route path="/registrations">
                        <RegistrationsComponent/>
                    </Route>
                    <Route path="/sms">
                        <SmsComponent/>
                    </Route>
                    <Route path="/">
                        <RegistrationForm/>
                    </Route>
                </Switch>
                <div className="App">

                </div>
            </Router>
        </div>
    );
}

export default App;
